@import '../../Theme/Styles/variables';

.iso__breadCrumb {
  position: relative;
  & a {
    display: inline-block;
  }
}
.prevent_click {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
