@import '../../Theme/Styles/variables';
@import '../../Theme/Styles/utils/mediaQueries';

.Poster {
  position: relative;

  &__image {
    max-height: 50vh;

    > img {
      max-height: 50vh;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
