@import '../../Theme/Styles/variables';

.Image {
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100%;
  justify-content: center;
  align-items: center;

  img {
    object-fit: cover;
    max-width: 100%;
    height: 100%;
    max-height: 50vh;
  }

  &__caption {
    display: flex;
    justify-content: space-between;
  }

  :global(.thumbnail) {
    width: 75px;
    height: 75px;
  }

  // These sizes are the bootstrap breakpoint sizes
  :global(.small) {
    width: 576px;
    height: auto;
  }

  :global(.medium) {
    width: 768px;
    height: auto;
  }

  :global(.large) {
    width: 992px;
    height: auto;
  }

  :global(.square) {
    width: 300px;
    height: 300px;
  }

  :global(.custom) {
    height: unset;
  }
}
