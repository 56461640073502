@import '../../Theme/Styles/variables';
@import '../../Theme/Styles/utils/mediaQueries';

.communicationButton {
  width: 3rem;
  height: 3rem;
  position: fixed;
  bottom: 70px;
  right: 25px;
  background-color: $primary !important;
  border-color: $primary;
  z-index: 1050;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  padding: 0;

  &::before {
    content: none;
  }

  &:not(:disabled):not(.disabled):active,
  &:focus {
    background-color: $primary;
    border-color: $primary;
    box-shadow: none;
  }

  & svg {
    color: $white;
    font-size: 1.25rem;
  }

  &:hover {
    svg {
      color: $white;
    }
  }

  &__alt {
    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      bottom: 170px !important;
    }
    @media (max-width: map-get($grid-breakpoints, 'sm')) {
      bottom: 210px !important;
    }
  }
}
