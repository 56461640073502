@import '../../Theme/Styles/variables';

@mixin arrow($degrees) {
  opacity: 1;

  &:active,
  &:focus {
    opacity: 1;

    > span:first-child::after {
      border: solid $gray-600;
      border-width: 0 3px 3px 0;
      opacity: 0.5;
    }
  }

  &:hover {
    opacity: 1;

    > span:first-child::after {
      opacity: 1;
      border: solid $primary;
      border-width: 0 3px 3px 0;
    }
  }

  > span:first-child {
    background-image: none !important;
  }
}

.quotes {
  box-shadow: 0 4px 10px $gray-400;
  position: relative;
  overflow: hidden;

  &Container {
    padding: 22px 0;
    position: relative;
  }

  &Item {
    padding: 2rem 2.5rem;
  }

  &Indicators {
    margin-bottom: 4px;

    > li {
      background-color: $gray-600 !important;
      opacity: 0.5;
      height: 5px;

      &:global(.active) {
        background-color: $primary !important;
        opacity: 1;
      }
    }
  }

  &ControlLeft {
    width: 30px !important;

    @include arrow(135);
  }

  &ControlRight {
    width: 30px !important;

    @include arrow(-45);
  }

  &Text {
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 2rem;

    p {
      margin-bottom: 0.5rem;
    }
  }

  &Author {
    font-size: 0.85rem;
  }

  &IconLeft {
    position: absolute;
    left: 15px;
    bottom: -14px;
    filter: drop-shadow(0px 1000px 0 $primary);
    transform: translateY(-1000px);
  }

  &IconRight {
    position: absolute;
    right: 15px;
    top: -14px;
    filter: drop-shadow(0px 1000px 0 $primary);
    transform: translateY(-1000px);
  }
}
