@import '../Theme/Styles/variables';

.headline {
  &Divider {
    border-bottom: 5px solid $gray;

    span {
      border-bottom: 5px solid $primary;
      display: inline-block;
      margin-bottom: -5px;
      padding-right: 25px;
    }
  }
}
