@import '../../Theme/Styles/variables';
@import '../../Theme/Styles/utils/mediaQueries';

.navigateUpButton {
  width: 3rem;
  height: 3rem;
  position: fixed;
  bottom: 15px;
  right: 25px;
  z-index: 1050;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  padding: 0;

  &::before {
    content: none;
  }

  & svg {
    color: $white;
    font-size: 1.25rem;
  }

  &:hover {
    svg {
      color: $white;
    }
  }

  &__alt {
    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      bottom: 116px !important;
    }
    @media (max-width: map-get($grid-breakpoints, 'sm')) {
      bottom: 156px !important;
    }
  }
}

.navigateUpButton__hide {
  opacity: 0;
  animation: hide 0.75s ease-out;
}

.navigateUpButton__show {
  animation: show 0.75s ease-out;
}

@keyframes hide {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(100px);
  }
}

@keyframes show {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
