@import '../../Theme/Styles/variables';

.accordion__element {
  position: relative;
  border-bottom: 2px solid $gray-100 !important;
  margin-bottom: 24px;

  &__button {
    background-color: $white;
    color: initial !important;
    width: 100%;
    text-align: left;
    position: relative;
    text-transform: initial;
    padding-left: 1rem;
    background-color: $white !important;
    border-radius: 4px;
    border: 2px solid $gray-100 !important;

    &:active,
    &:focus,
    &:hover {
      background-color: $white !important;
      color: initial !important;
      box-shadow: none !important;
    }

    > span:first-child {
      font-size: 1.25rem;
      font-weight: 600;
    }
  }

  &__chevron {
    transition: transform 0.3s ease-in;
    color: $primary;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: center;
    font-size: 1.4rem;

    &Up {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &__card {
    border: 0;
    padding: 1rem;

    p {
      margin-bottom: 0;
    }
  }
}
